import HeaderMenu from "../main/HeaderMenu"
import LivekitService from "../services/LivekitService"
import {SuccessMessage, RoomsPageSize} from "../constants";
import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom"



const Livekit = () => {
    const [roomsToShow, setRoomsToShow] = useState([]);
    const [countOfRooms, setCountOfRooms] = useState(0);

    useEffect(()=> {
        LivekitService.getRoomsList().then((response)=>{
            if (response.data.Success == SuccessMessage) {
               // console.log("rooms", response.data.Data.Rooms)
                setRoomsToShow(response.data.Data.Rooms)
            }
        })
    }, []);

    if (!roomsToShow) {
        return (<div><HeaderMenu/>Have No rooms???</div>)
    }

    let rooms = []
    let i = 0
    for (const v of roomsToShow) {
        rooms.push(<Room key={i} room={v}/>)
        i++
    }
    const listStyle = {
        border: "1px solid black",
        marginTop: "10px"
    }
    return (
        <div><HeaderMenu/>
        <br/><br/>
            <div style={listStyle}>
                {rooms}
            </div>
        </div>
    )
}

const Room = (props)=>{
    const navigate = useNavigate();

    const nameStyle = {
        padding: "10px",
        fontSize: "20px",
        fontWeight: "bold",
    }
    const statusesStyle = {
        padding: "7px",
    }
    const entryStyle = {
        border: "1px solid black",
    }
    const goToParticipant = ()=>{
        navigate('/livekit/participants/'+props.room.Name)
    }
    return (
        <div style={entryStyle}>
            <span style={nameStyle}>{props.room.Name}</span><span style={statusesStyle}>{props.room.NumOfParticipants}</span><span style={statusesStyle}>Max: {props.room.MaxParticipants}</span>

            <span><img style={{width: "40px"}} onClick={goToParticipant} src="/team.png"/></span>

        </div>
    )
}

// respRooms = append(respRooms, eresponses.LivekitRoom{
//     Name:              room.Name,
//     NumOfParticipants: int(room.NumParticipants),
//     MaxParticipants:   int(room.MaxParticipants),
//     EmptyTimeout:      int(room.EmptyTimeout),
// })

export default Livekit
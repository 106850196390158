import Api from "./Api"



const getLastPage = async function(pageSize) {
    return await Api.post(`/talks/lastpage`, {PageSize: pageSize})
}

const getPage = async function(pageSize, pageNum) {
    return await Api.post(`/talks/page`, {PageSize: pageSize, PageNum: pageNum})
}

const TalkService = {
    getLastPage,
    getPage,
  };
  
export default TalkService;
import HeaderMenu from "../main/HeaderMenu"
import RoomService from "../services/RoomService"
import {SuccessMessage, RoomsPageSize} from "../constants";
import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom"
import { Pagination, Stack, Tab, Tabs, Box, IconButton, Chip } from '@mui/material';
import PeopleIcon from "@mui/icons-material/People"

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const Rooms = () => {
    const [roomsToShow, setRoomsToShow] = useState([]);
    const [countOfRooms, setCountOfRooms] = useState(0);
	const [value, setValue] = useState(0);
    const [pagesCount, setPagesCount] = useState(0);
    const [page, setPage] = useState(0);

    
    useEffect(()=> {
        RoomService.getLastPage(RoomsPageSize, value == 0).then((response)=>{
            if (response.data.Success == SuccessMessage) {
               // console.log("rooms", response.data.Data.Rooms)
                setRoomsToShow(response.data.Data.Rooms)
                setCountOfRooms(response.data.Data.CountOfRooms)
                setPagesCount(response.data.Data.PagesCount)
                setPage(response.data.Data.PagesCount)
            }
        })
    }, [value]);
    useEffect(()=> {
        RoomService.getPage(RoomsPageSize, page, value == 0).then((response)=>{
            if (response.data.Success == SuccessMessage) {
               // console.log("rooms", response.data.Data.Rooms)
                setRoomsToShow(response.data.Data.Rooms)
            }
        })
    }, [page]);

    if (!roomsToShow) {
        return (<div><HeaderMenu/>Have No rooms???</div>)
    }

    let roomsList = []
    let i = 0
    for (const v of roomsToShow) {
        roomsList.push(<Room key={i} room={v}/>)
        i++
    }
    const listStyle = {
        border: "1px solid black",
        marginTop: "10px"
    }
    const handleChange = (event, newValue) => {
		setValue(newValue);
	};
    const pagesChange = (e, v)=>{
		setPage(v)
	}
    return (
        <div>
            <HeaderMenu/>
            <br/>
            <br/>
            <Box sx={{ width: '100%' }}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
								<Tab label="Online" {...a11yProps(0)} />
								<Tab label="All" {...a11yProps(1)} />
							</Tabs>
						</Box>

						<TabPanel value={value} index={0}>
							<Stack 
								spacing={3} 
							>
                                <div>Количество: {countOfRooms}</div>
                                <div>Последняя страница:</div>
                                <div style={listStyle}>
                                    {roomsList}
                                </div>
                                <Box style={{ display: "flex", justifyContent: 'center'}}>
                                    {pagesCount > 1 ? <Pagination count={pagesCount} shape="rounded" page={page} onChange={pagesChange}/> : null}
                                </Box>
							</Stack>
						</TabPanel>

						<TabPanel value={value} index={1}>
							<Stack 
								spacing={2} 
							>
                                <div>Количество: {countOfRooms}</div>
                                <div>Последняя страница:</div>
                                <div style={listStyle}>
                                    {roomsList}
                                </div>
                                <Box style={{ display: "flex", justifyContent: 'center'}}>
                                    {pagesCount > 1 ? <Pagination count={pagesCount} shape="rounded" page={page} onChange={pagesChange}/> : null}
                                </Box>                                
							</Stack>							
						</TabPanel>
					</Box>
        </div>
    )
}

export default Rooms

export const Room = function(props) {
    const navigate = useNavigate();
    const [participants, setParticipants] = useState([]);

    const nicknameStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "7px",
    }
    const statusesStyle = {
        padding: "7px",
    }
    const entryStyle = {
        border: "1px solid black",
    }
    const eyeIMG = ""
    const goToVideos = ()=>{
        navigate('/rooms/videos/'+props.room.ID+"/"+props.room.ID+"-000001.mp4")
    }
    const handleOpenParticipants = ()=>{
        RoomService.getRoomParticipants(props.room.ID).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                console.log("Participants: ", response.data.Data.Participants)
                setParticipants(response.data.Data.Participants)
            }
        })
    }
    return (
        <div style={entryStyle}>
            <span style={nicknameStyle}>{props.room.ID}</span>
            <span style={statusesStyle}>{props.room.RoomType}</span>
            <span style={statusesStyle}>{props.room.RoomStatus}</span>
            <span style={statusesStyle}>{props.room.AddTime}</span>
            <span style={statusesStyle}>{props.room.WayTime}</span>
            <span style={statusesStyle}>{props.room.Theme}</span>
            <span><img style={{width: "40px"}} onClick={goToVideos} src="/eye.jpg"/></span>
            <span><IconButton onClick={handleOpenParticipants}><PeopleIcon /></IconButton></span>
            {participants && 
				<ul style={{display: "flex", flexWrap: "wrap", padding: 0, margin: 0, paddingTop: "6px", gap: "4px", maxHeight: "52px", overflow: "hidden", listStyle: "none"}}>
					{participants.map((item, index) => (
						<Chip color={ParticipantPhaseToColor(item.Phase)} avatar={<div style={{width: "20px", height: "20px", backgroundColor: ParticipantRoleToColor(item.RoomRole), borderRadius: "20px"}}></div>} label={item.Nickname} size="small" key={index} />
					))}
				</ul>
			}
        </div>
    )
}

function ParticipantRoleToColor(role) {
    switch (role) {
        case "admin", "common":
            return "#4aba61"
        case "blocked":
            return "#000000"
        case "leaved":
            return "#bf3f34"
        case "spectator":
            return "#171ed4"    
    }
    return "#ffffff"
}

function ParticipantPhaseToColor(phase) {
    switch (phase) {
        case "active":
            return "success"
        case "ended":
            return "secondary"
        case "left":
            return "primary"
    }
    return ""
}
// type RoomInfo struct {
// 	ID           uint64    `json:"ID"`
// 	EmptyTimeout int       `json:"EmptyTimeout" db:"empty_timeout"`
// 	RoomType     string    `json:"RoomType" db:"room_type"`
// 	RoomStatus   string    `json:"RoomStatus" db:"room_status"`
// 	TypeEntityID uint64    `json:"TypeEntityID" db:"type_entity_id"`
// 	AddTime      time.Time `json:"AddTime" db:"add_time"`
// 	WayTime      string    `json:"WayTime" db:"way_type"`
// 	Theme        string    `json:"Theme" db:"theme"`
// }
import HeaderMenu from "../main/HeaderMenu"
import OrderService from "../services/OrderService"
import React, { useEffect, useState } from "react";
import {useParams, useNavigate} from "react-router-dom";
import {SuccessMessage,} from "../constants";
import ManageAccounts from "@mui/icons-material/ManageAccounts"
import { IconButton, Chip } from '@mui/material';



const OrdersUser = ()=>{
    const [usersOrders, setUsersOrders] = useState([]);

    let { user_id } = useParams();

    useEffect(()=> {
        OrderService.getUserOrders(user_id).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setUsersOrders(response.data.Orders.Orders)
            }
        })
    },[])

    return (
        <div>
            <HeaderMenu/>
            <br/>
            {usersOrders != null && usersOrders.length > 0 ? 
            <>{usersOrders.map((item, index) => (
							<div>
									<Order
                                        key={index}
										OrderID={item.ID}
                                        Prolongate={item.Prolongate}
                                        HavePurchase={item.HavePurchase}
										Nickname={""}
									/>
							</div>
						))}</> : <div>Нет заказов</div>}
        </div>
    )
}

export default OrdersUser;


export const Order = (props)=>{
    const navigate = useNavigate();

    const orderIDStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "7px",
    }
    const userNicknameStyle = {
        padding: "7px",
    }
    const entryStyle = {
        border: "1px solid black",
    }
    const handleOpenOrderDetails = ()=>{
        navigate('/order/details/'+props.OrderID)
    }
    console.log("ASASSA ", props.Prolongate)
    return (
        <div style={entryStyle}>
            <span style={orderIDStyle}>{props.OrderID}</span>
            {props.Prolongate ? <span style={userNicknameStyle}>Prolongation</span> : null}
            {props.HavePurchase ? <span style={userNicknameStyle}>Purchased</span> : null}
            <span style={userNicknameStyle}>{props.Nickname}</span>
            <span><IconButton onClick={handleOpenOrderDetails}><ManageAccounts /></IconButton></span>
        </div>
    )
}
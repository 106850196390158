





export const SuccessMessage = "Yeah"
export const UsersPageSize = 10
export const RoomsPageSize = 10
export const TalksPageSize = 10

export const MaxChatMessageLength = 500

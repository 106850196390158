

import HeaderMenu from "../main/HeaderMenu"
import UserService from "../services/UserService"
import React, { useEffect, useState } from "react";
import {SuccessMessage,} from "../constants";







const UserReports = ()=>{
    const [lastReports, setLastReports] = useState([]);


    useEffect(()=> {
        UserService.getLastUserReports().then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setLastReports(response.data.UserReports.Reports)
            }
        })
    },[])
    return (
        <div>
            <HeaderMenu/>
            <br/>
            {lastReports != null && lastReports.length > 0 && lastReports.map((item, index) => (
							<div>
									<Report
                                        key={index}
										report={item}
									/>
							</div>
						))}            
        </div>
    )
}

export default UserReports;


export const Report = (props)=>{

    const reportIDStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "7px",
    }
    const userNicknameStyle = {
        padding: "7px",
    }
    const entryStyle = {
        border: "1px solid black",
    }
    return (
        <div style={entryStyle}>
            <span style={reportIDStyle}>Reporter: {props.report.ReporterNickname}</span>
            <span style={userNicknameStyle}>Guilty: {props.report.GuiltyNickname}</span>
            <span style={userNicknameStyle}>Description: {props.report.Decription}</span>
            <span style={userNicknameStyle}>Time: {props.report.ReportTime}</span>
        </div>
    )
}
import HeaderMenu from "../main/HeaderMenu"
import LivekitService from "../services/LivekitService"
import {SuccessMessage, RoomsPageSize} from "../constants";
import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";




const Participants = () => {
    const [participantsToShow, setParticipantsToShow] = useState([]);

    let { roomname } = useParams(); 
    useEffect(()=> {
        LivekitService.getParticipants(roomname).then((response)=>{
            if (response.data.Success == SuccessMessage) {
               // console.log("rooms", response.data.Data.Rooms)
               setParticipantsToShow(response.data.Data.Participants)
            }
        })
    }, []);

    if (!participantsToShow) {
        return (<div><HeaderMenu/>Have No participants???</div>)
    }

    let participants = []
    let i = 0
    for (const v of participantsToShow) {
        participants.push(<Participant key={i} participant={v}/>)
        i++
    }
    const listStyle = {
        border: "1px solid black",
        marginTop: "10px"
    }
    return (
        <div><HeaderMenu/>
        <br/><br/>
            <div style={listStyle}>
                {participants}
            </div>
        </div>
    )
}

export default Participants

const Participant = (props) => {
    const nameStyle = {
        padding: "10px",
        fontSize: "20px",
        fontWeight: "bold",
    }
    const statusesStyle = {
        padding: "7px",
    }
    const entryStyle = {
        border: "1px solid black",
    }
    let tracks = []
    if (props.participant.Tracks != null) {
        let i = 0
        for (const v of props.participant.Tracks) {
            tracks.push(<Track key={i} track={v}/>)
            i++
        }
    }

    return (
        <div style={entryStyle}>
            <div style={nameStyle}>{props.participant.Identity}</div>
            {tracks}
        </div>
    )
}

const Track = (props) => {
    const nameStyle = {
        padding: "10px",
        fontSize: "20px",
        fontWeight: "bold",
    }
    const statusesStyle = {
        padding: "7px",
    }
    const entryStyle = {
        border: "1px solid black",
    }
    return (
        <div style={entryStyle}>
            <span style={statusesStyle}>{props.track.Name}</span>
            <span style={statusesStyle}>{props.track.Source}</span>
            <span style={statusesStyle}>Type: {props.track.Type}</span>
            <span style={statusesStyle}>Muted?: {props.track.Muted}</span>
            <span style={statusesStyle}>LayersCount: {props.track.LayersCount}</span>
        </div>
    )
}


// type LivekitParticipant struct {
// 	Identity string         `json:"Identity"`
// 	Tracks   []LivekitTrack `json:"Tracks"`
// }

// type LivekitTrack struct {
// 	Name        string `json:"Name"`
// 	Sid         string `json:"Sid"`
// 	Source      string `json:"Source"`
// 	Type        string `json:"Type"`
// 	Muted       bool   `json:"Muted"`
// 	LayersCount int    `json:"LayersCount"`
// }
import logo from './logo.svg';
import './App.css';
import Index from "./main/Index";
import Users from "./users/Users";
import Rooms from "./rooms/Rooms";
import Talks from "./talks/Talks";
import Livekit from "./livekit/Livekit";
import Participants from "./livekit/Participants";
import Videos from "./rooms/Videos";
import BannedChat from "./users/BannedChat"
import {
  RecoilRoot
} from 'recoil';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom"
import { I18nPropvider, LOCALES } from './i18n';
import React, {useMemo, useState} from 'react';
import UserDetails from "./users/UserDetails"
import Error from "./main/Error"
import OrdersUser from "./orders/OrdersUser"
import LastOrders from "./orders/LastOrders"
import OrderDetails from "./orders/OrderDetails"
import UserReports from  "./users/UserReports"

function App() {
  const [locale, setLocale] = useState(LOCALES.RUSSIAN); 

  return (
    <RecoilRoot>
      <I18nPropvider locale={locale} defaultLocale={LOCALES.RUSSIAN}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Index/>}/>
          <Route path='/error' element={<Error/>}/>
          <Route path='/users' element={<Users/>} />
          <Route path='/user_details/:user_id' element={<UserDetails/>} />
          <Route path='/rooms' element={<Rooms/>} />
          <Route path='/talks' element={<Talks/>} />
          <Route path='/livekit' element={<Livekit/>} />
          <Route path='/livekit/participants/:roomname' element={<Participants/>} />
          <Route path='/rooms/videos/:roomid/:videofile' element={<Videos/>} />
          <Route path='/talks' element={<Talks/>} />
          <Route path='/banned_chats/:user_id' element={<BannedChat/>} />
          <Route path='/orders/user/:user_id' element={<OrdersUser/>} />
          <Route path='/orders/last' element={<LastOrders/>} />   
          <Route path='/order/details/:order_id' element={<OrderDetails/>} />  
          <Route path='/reports/last' element={<UserReports/>} />                              
        </Routes>
      </BrowserRouter>
      </I18nPropvider>
    </RecoilRoot>
  );
}

export default App;

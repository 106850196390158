import Api from "./Api"
import ApiLivekit from "./ApiLivekit"



const getRoomsList = async function() {
    return await Api.post(`/livekit/allrooms`, {})
}

const getParticipants = async function(roomName) {
  return await Api.post(`/livekit/getparticipants`, {RoomName: roomName})
}

const getRoomVideos = async function(livekitServerID, roomID) {
  return await ApiLivekit.post("/" + livekitServerID + `/rooms/getvideos`, {RoomID: roomID})
}

const LivekitService = {
    getRoomsList,
    getParticipants,
    getRoomVideos,
  };
  
export default LivekitService;
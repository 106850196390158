import HeaderMenu from "../main/HeaderMenu"
import OrderService from "../services/OrderService"
import React, { useEffect, useState } from "react";
import {useParams, useNavigate} from "react-router-dom";
import {SuccessMessage,} from "../constants";
import ManageAccounts from "@mui/icons-material/ManageAccounts"
import { IconButton, Chip } from '@mui/material';










const OrderDetails = ()=>{
    const [orderDetails, setOrderDetails] = useState({});

    let { order_id } = useParams();

    useEffect(()=> {
        OrderService.getOrderDetails(order_id).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setOrderDetails(response.data.Order)
            }
        })
    },[])

    const orderTitleStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "7px",
    }
    const orderInfoStyle = {
        padding: "7px",
    }
    return (
        <div>
            <HeaderMenu/>
            <br/>
            {orderDetails.ID ? 
            <div>
                <span style={orderTitleStyle}>{orderDetails.Nickname}</span>
                <span style={orderInfoStyle}>{orderDetails.ID}</span>
                {orderDetails.Prolongate ? <span style={orderInfoStyle}>Prolongation</span> : null}
                {orderDetails.OrderPurchase && orderDetails.OrderPurchase.ID != 0 ? 
                  <div>
                    <span style={orderInfoStyle}>PURCHASE:</span>
                    <span style={orderInfoStyle}>Type: {orderDetails.OrderPurchase.PurchaseType}</span>
                    <span style={orderInfoStyle}>Time: {orderDetails.OrderPurchase.PurchasedTime}</span>
                    <span style={orderInfoStyle}>Amount: {orderDetails.OrderPurchase.Amount}</span>
                  </div> : <div>No Purchase</div>}
                {orderDetails.Notifications ? 
                  <div>
                        {orderDetails.Notifications.map((item, index) => (
							<div>
									<Notification
                                        key={index}
										Notification={item}
									/>
							</div>
						))}

                  </div>
                  : <div>No notifications</div>
                }
                  </div> : <div>Not loaded</div>}

        </div>
    )
}


export const Notification = (props)=>{

    const notificationStatusStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "7px",
    }
    const notificationInfoStyle = {
        padding: "7px",
    }
    const entryStyle = {
        border: "1px solid black",
    }
    return (
        <div style={entryStyle}>
            <span style={notificationStatusStyle}>{props.Notification.Status}</span>
            {props.Notification.ErrorCode != "0" ? <span style={notificationInfoStyle}>ErrorCode: {props.Notification.ErrorCode}</span> : null}
            <span style={notificationInfoStyle}>PaymentID: {props.Notification.PaymentID}</span>
            <span style={notificationInfoStyle}>Message: {props.Notification.Message}</span> 
            <span style={notificationInfoStyle}>Details: {props.Notification.Details}</span> 
            {props.Notification.Success ? <span style={notificationInfoStyle}>Success</span> : <span style={notificationInfoStyle}>Fail</span>}
            <span style={notificationInfoStyle}>TimeHappened: {props.Notification.TimeHappened}</span>
        </div>
    )
}

export default OrderDetails;
import React, { useEffect, useState } from "react";
import RoomService from "../services/RoomService"
import LivekitService from "../services/LivekitService"
import TokenService from "../services/TokenService"
import {useParams} from "react-router-dom";
import {SuccessMessage} from "../constants";
import {useNavigate} from "react-router-dom"
import {domainNameAPI, protocolAPI, apiPort} from "../environment"
import HeaderMenu from "../main/HeaderMenu"
import {livekitServers} from "../environment"

const Videos = () => {
    const [roomVideos, setRoomVideos] = useState([]);
    const [serverID, setServerID] = useState("");

    let { roomid, videofile } = useParams(); 
    useEffect(()=> {
        let serverID = livekitServers[0]
        setServerID(serverID)
        LivekitService.getRoomVideos(serverID, roomid).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setRoomVideos(response.data.Data.Videos)
            }
        })
    }, [])
    if (roomVideos.length == 0) {
        return (<div>Has no files</div>)
    }

    let videosHTML = []
    let i = 0
    for (const v of roomVideos) {
        i++
        videosHTML.push(<VideoHTML key={i} roomid={roomid} file={v}></VideoHTML>)
    }
    let videoSrc = protocolAPI+domainNameAPI+apiPort+"/livekitapi/"+serverID+"/rooms/video/"+roomid+"/"+videofile
    const token = TokenService.getLocalToken();
    const videoStyle = {
        width: "1024px"
    }

    return (
    <div>
        <HeaderMenu/>
        <video style={videoStyle} controls autoplay>
            <source src={videoSrc} type="video/mp4"></source>
        </video>
        <div>{videosHTML}</div>
    </div>)
}

const VideoHTML = (props)=>{
    const navigate = useNavigate();

    const chooseIt = ()=>{
        window.location.href = "/rooms/videos/"+props.roomid+"/"+props.file
    }
    const videoStyle = {
        padding: "10px",
        cursor: "pointer",
    }
    return (
        <span style={videoStyle} onClick={chooseIt}>{props.file}</span>
    )
}

export default Videos
import {useParams, useNavigate} from "react-router-dom";
import { Container, Divider, Stack, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import RoomService from "../services/RoomService"
import UserService from "../services/UserService"
import React, { useEffect, useState } from "react";
import {SuccessMessage,} from "../constants";
import {Room} from "../rooms/Rooms"
import {Report} from "./UserReports"

const UserDetails = () => {
	let { user_id } = useParams();
    const navigate = useNavigate();

    const [roomsStory, setRoomsStory] = useState([]);
    const [nickName, setNickName] = useState("");
    const [info, setInfo] = useState(null);
    const [chatsStory, setChatsStory] = useState([]);
    const [userReports, setUserReports] = useState([]);

    useEffect(()=> {
        UserService.getUserInfo(user_id).then((response)=>{
            if (response.data.Success == SuccessMessage) {
               setNickName(response.data.UserInfo.Nickname)
               setInfo(response.data.UserInfo)
            }
        })
    }, []);
    
    useEffect(()=> {
        UserService.getUserMessages(user_id).then((response)=>{
            if (response.data.Success == SuccessMessage) {
               setChatsStory(response.data.UserMessages.Messages)
            }
        })
    }, []);    

    useEffect(()=> {
        RoomService.getLastUserRooms(user_id).then((response)=>{
            if (response.data.Success == SuccessMessage) {
               // console.log("rooms", response.data.Data.Rooms)
               setRoomsStory(response.data.Data.Rooms)
            }
        })
    }, [])

    useEffect(()=> {
        UserService.getUserReports(user_id).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setUserReports(response.data.UserReports.Reports)
            }
        })
    },[])

    let roomsList = []
    let i = 0
    for (const v of roomsStory) {
        roomsList.push(<Room key={i} room={v}/>)
        i++
    }

    let chatsMsgsList = []
    i = 0
    for (const v of chatsStory) {
        chatsMsgsList.push(<ChatMessage key={i} msg={v}/>)
        i++
    }

    const banUser = ()=>{
        UserService.banUser(user_id).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                alert("Banned!")
            }
        })
    }
    const unbanUser = ()=>{
        UserService.unbanUser(user_id).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                alert("UNbanned!")
            }
        })
    }
    const removeAllMessages = ()=>{
        UserService.removeAllMessages(user_id).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                alert("Removed!")
            }
        })
    }
    const toOrders = ()=>{
        navigate("/orders/user/"+user_id)
    }
    
    const menuButtonStyle = {
        marginRight: "20px"
    }

    return (<Box
        sx={{
            minHeight: '100vh',
            bgcolor: 'background.default',
            color: 'text.primary',
        }}
    >
        <Typography variant="h2">
			{nickName}
		</Typography>
        <Typography variant="h6">
			{JSON.stringify(info)}
		</Typography>
        <Button variant="contained" size="large" onClick={banUser} style={{menuButtonStyle}}>
			Забанить
		</Button> 
        <Button variant="contained" size="large" onClick={unbanUser} style={{menuButtonStyle}}>
			Разбанить
		</Button> 
        <Button variant="contained" size="large" onClick={removeAllMessages} style={{menuButtonStyle}}>
			Удалить все сообщения
		</Button>  
        <Button variant="contained" size="large" onClick={toOrders} style={{menuButtonStyle}}>
			Заказы
		</Button>                        
        <Stack spacing={1}>
            {roomsList}
        </Stack>
        <Stack spacing={1}>
            {chatsMsgsList}
        </Stack>
        <Stack spacing={1}>
            {userReports != null && userReports.length > 0 && userReports.map((item, index) => (
							<div>
									<Report
                                        key={index}
										report={item}
									/>
							</div>
						))} 
        </Stack>
    </Box>)
}

export default UserDetails;



function ChatMessage(props) {
    return (
        <div>
            <b>{props.msg.Type} {props.msg.EntityID}</b> <span>{props.msg.Message}</span> <span style={{fontSize: "xx-small"}}>({props.msg.TimeTexted})</span>
        </div>
    )
}
import HeaderMenu from "../main/HeaderMenu"
import UserService from "../services/UserService"
import {SuccessMessage, UsersPageSize} from "../constants";
import React, { useEffect, useState } from "react";
import { TextField } from '@mui/material';
import ManageAccounts from "@mui/icons-material/ManageAccounts"
import {useNavigate} from "react-router-dom"
import { IconButton, Chip } from '@mui/material';


function Users() {
    const [usersToShow, setUsersToShow] = useState([]);
    const [countOfUsers, setCountOfUsers] = useState(0);
    const [searchString, setsearchString] = useState("");

    useEffect(()=> {
        if (searchString == "" ) {
            UserService.getLastPage(UsersPageSize).then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    setUsersToShow(response.data.Data.Users)
                    setCountOfUsers(response.data.Data.CountOfUsers)
                }
            })
        } else {
            UserService.findUserByNick(searchString).then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    setUsersToShow(response.data.Data.Users)
                }
            })
        }

    }, [searchString]);

    let usersList = []
    let i = 0
    for (const v of usersToShow) {
        usersList.push(<User key={i} user={v}/>)
        i++
    }
    const listStyle = {
        border: "1px solid black",
        marginTop: "10px"
    }
    const changeSearchString = (e)=>{
		setsearchString(e.target.value);
    }
    return (
        <div>
            <HeaderMenu/>
            <br/>
            <TextField 
					value={searchString}
					onChange={changeSearchString}
					fullWidth 
					id="name" 
					label="Nickname"
					variant="outlined" 
					size='small'
				/>
            <br/>
            <div>Количество: {countOfUsers}</div>
            <div>Последняя страница:</div>
            <div style={listStyle}>
                {usersList}
            </div>
        </div>
    )
}

export default Users;

function User(props) {
    const navigate = useNavigate();

    const nicknameStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "7px",
    }
    const statusesStyle = {
        padding: "7px",
    }
    const entryStyle = {
        border: "1px solid black",
    }
    const handleOpenUserDetails = ()=>{
        navigate('/user_details/'+props.user.ID)
    }
    return (
        <div style={entryStyle}>
            <span style={nicknameStyle}>{props.user.Nickname}</span>
            <span style={statusesStyle}>{props.user.AccountStatus}</span>
            <span style={statusesStyle}>{props.user.AccountType}</span>
            <span style={statusesStyle}>til</span>
            <span style={statusesStyle}>{props.user.AccountTypeTil}</span>
            <span><IconButton onClick={handleOpenUserDetails}><ManageAccounts /></IconButton></span>
        </div>
    )
}

// type UserInfo struct {
// 	ID             uint64    `json:"ID"`
// 	Nickname       string    `json:"Nickname"`
// 	AccountStatus  string    `json:"AccountStatus" db:"account_status"`
// 	AccountTypeTil time.Time `json:"AccountTypeTil" db:"account_type_til"`
// 	AccountType    string    `json:"AccountType" db:"account_type"`
// 	IconUrl        string    `json:"IconUrl" db:"icon_url"`
// }
import Api from "./Api"



const getLastPage = async function(pageSize, isOnline) {
    return await Api.post(`/rooms/lastpage`, {PageSize: pageSize, IsOnline: isOnline})
}

const getPage = async function(pageSize, pageNum, isOnline) {
    return await Api.post(`/rooms/page`, {PageSize: pageSize, PageNum: pageNum, IsOnline: isOnline})
}

const getRoomParticipants = async function(roomID) {
    return await Api.post(`/rooms/participants`, {RoomID: parseInt(roomID)})
}

const getLastUserRooms = async function(userID) {
    return await Api.post(`/rooms/getLastUserRooms`, {UserID: parseInt(userID)})
}

const RoomService = {
    getLastPage,
    getPage,
    getRoomParticipants,
    getLastUserRooms,
  };
  
export default RoomService;
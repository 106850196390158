import { LOCALES } from '../locales';

export default {
  [LOCALES.RUSSIAN]: {
    is_visible_others: 'Видно другим?',
    games: `Игры`,
    rooms: `Комнаты`,
    articles: `Статьи`,
    login: `Вход`,
    registration: `Регистрация`,
    about: `О себе`,
    name: `Имя`,
    surname: `Фамилия`,
    phone: `Телефон`,
    country: `Страна`,
    region: `Регион`,
    city: `Город`,
    change_password: `Изменить пароль`,
    cancel: `Отменить`,
    save: `Сохранить`,
    username: `Имя пользователя`,
    password: `Пароль`,
    repeat_password: `Повторите пароль`,
    invalid_password: `Неверный пароль`,
    sign_up: `Зарегистрироваться`,
    email: `Почта`,
    nickname: `Никнейм`,
    play: `Играть`,
    create_your_own: `Создать свое`,
    lobby_hackword: `Лобби Hackword`,
    lobby_iamalive: `Лобби Я живое?`,
    hackword: `Hackword`,
    who_am_i: `Я живое?`,
    for_their_own: `Для своих`,
    everyone_over_here: `Все сюда!`,
    creating_game: `Создание игры`,
    name_game: `Название игры`,
    number_players: `Количество игроков`,
    can_hear_everyone: `Слышно всех и всегда`,
    just_your_team: `или только свою команду`,
    age_restrictions: `18+ (мат, пошлости)`,
    closed: `Закрытая`,
    create: `Создать`,
    teams: `Команды`,
    score: `Счет`,
    set_of_words: `Набор слов`,    
    sec: `сек`,
    time_for_round: `Время на раунд`,
    words_to_victory: `Слов до победы`,
    settings: `Настройки`,
    easy: `Легкие`,
    complex: `Сложные`,
    professions: `Профессии`,
    animals: `Животные`,
    places_on_earth: `Места на Земле`,
    number_of_teams: `Количество команд`,
    start_over: `Начать заново`,
    shuffle_players: `Перемешать игроков`,
    ready: `Готов`,
    round: `Раунд`,
    all_words: `Все слова`,
    you_won: `Вы победили`,
    your_team: `Ваша команда`,
    your_place: `Ваше место:`,
    exit: `Выйти`,
    another_one: `Еще одну`,
    create_room: `Создать комнату`,
    casual_interlocutors: `Случайные собеседники`,
    creating_room: `Создание комнаты`,
    room_name: `Название комнаты`,
    number_participants: `Количество участников`,
    tag_text: `Текст тэга`,
    add: `Добавить`,
    your_gender: `Ваш пол`,
    gender_interlocutor: `Пол собеседника`,
    your_age: `Ваш возраст`,
    age_interlocutor: `Возраст собеседника`,
    search: `Искать`,
    male: `М`,
    female: `Ж`,
    no_matter: `Не важно`,
    young: `Молод`,
    mature: `Зрел`,
    your_account_has_been_blocked: `Ваш аккаунт заблокирован по причине`,
    inappropriate_behavior: `неподобаемое поведение`,
    you_can_protest: `Вы можете опротестовать это в чате. Просто опишите ситуацию как её видите вы.`,
    account_will_be_unblocked: `Аккаунт будет разблокирован`,
    by_popularity: `По популярности`,
    by_time: `По времени`,
    cycle: `Цикл`,
    gender: `Пол`,
    age: `Возраст`,
    youth: `молодость`,
    next: `Следующий`,
    home_title: `Играй, знакомься, общайся`,
    home_description: `В нашей жизни кроме материального капитала очень важно заработать и социальный капитал. А также хорошо провести время с друзьями или просто в компании еще недавно совсем незнакомых людей. Со всем этим мы тебе поможем. Присоединяйся.`,
    get_acquainted: `Знакомиться`,
    feature_games: `Играй с друзьями или со случайными людьми из интернета`,
    feature_soc: `Обсудите важную тему или знакомьтесь с новыми людьми`,
    feature_com: `Cоберите клуб по интересам или создайте сообщество друзей`,
    communities_title: `Общайтесь, участвуйте в событиях и находите друзей в сообществах!`,
    communities_desc: `Вступайте или создавайте своё`,
    create_community: `Создать сообщество`,
    my_communities: `Мои сообщества`,
    my_events: `Мои события`,
    communities: `Сообщества`,
    creating_community: `Создание сообщества`,
    type: `Тип`,
    title: `Название`,
    upload_logo: `Загрузить эмблему`,
    accept_everyone: `Принимать всех подряд`,
    description: `Описание`,
    date_and_time: `Дата и время`,
    view_room: `Вид комнаты`,
    view: `Вид`,
    creating_event: `Создание события`,
    back_to_events: `Обратно к списку событий`,
    join: `Присоединиться`,
    chat_events: `Чат события`,
    account: `аккаунт`,
    your_tariff: `Ваш тариф`,
    still_valid: `Действует еще`,
    subscription: `Подписка`,

    // Self part
    Female: `женский`,
    Male: `мужской`,
    Old: `зрелый`,
    Young: `молодой`,
    NotKnown: "-",
    NoMatter: "не важно",
    videoAudioSettings: "Видео/Аудио",
    camera: "Камера",
    microphone: "Микрофон",
    dynamic: "Динамик",
    hasNoCameras: "Похоже у вас нет доступных камер",
    from: "из",
    tel: "тел.",
    havenoanything: "ничего о себе не показал",
    complain: "пожаловаться",
    YouAreInTheRoon: "Вы в комнате",
    GoTo: "Перейти",
    YouLeavedTheRoom: "Вы вышли из комнаты",
    YouAlreadyInTheRoom: "Вы уже в комнате",
    ToEndRegisterCheckEmail: "Для завершения регистрации перейдите по ссылке в письме, которое мы вам отправили.",
    PressingRegisterYouAccept: "Нажимая «Зарегистрироваться», вы подтверждаете, что прочли и принимаете условия ",
    userAgreement: "пользовательское соглашение",
    andProvisions: " и положения ",
    privaciesPolicy: "политики конфиденциальности",
    notMatchingPasswords: "Пароли не совпадают",
    notARobotCheck: "Подтвердите, что вы не робот",
    СantEmptyNickname: "Никнейм не может быть пустым",
    Saved: "Сохранено",
    imageIsTooBigMoreThen3Mb: "Файл слишком большой (>3 мегабайт)",
    NowPassword: "Пароль сейчас",
    NewPassword: "Новый пароль",
    OneMoreNewPassword: "Еще раз новый пароль",
    Change: "Изменить",
    PasswordChanged: "Пароль изменен",
    PasswordIsNotTrue: "Неправильный пароль",
    cantbelonger: "не может быть длиннее",
    EmailOrPasswordIncorrect: "Неверный логин или пароль",
    Exit: "Выйти",
    YouAreAnAdmin: "Вы админ",
    RecruitingPlayers: "Идет набор игроков",
    StartGame: "Начать игру",
    YouAreAdmin: "Вы админ",
    CantStartGameWithOnePlayer: "Нельзя начать игру с одним игроком",
    BriefNote: "Краткая справка",
    HackwordNote: "Чтобы переместиться в другую команду кликните на неё. Админ может перетаскивать любых игроков из команды в команду. Также админ может менять настройки и начинать игру заново. У админа написано что он админ, обычно это первый игрок - левый верхний угол",
    StopPickingPlayers: "Остановить набор игроков (перейти к игре)",
    StartPickingPlayers: "Начать набор игроков",
    AdminCanStartGameOrPicking: "Админ может начать игру или набор игроков",
    YouAlreadyHaveMaximumPlayers: "В игре итак заданное количество игроков",
    UnAccesibleOnePlayerInTeam: "Нельзя чтобы в команде был один игрок",
    Apply: "Применить",
    ErrorOccured: "Произошла ошибка",
    HaveWinnerFinishGame: "Есть победитель. Закончить игру",
    JoinRoom: "Присоединиться",
    SpectatorMode: "Режим зрителя",

    // Self but formatted
    is_recruiting: "Идет набор",
    already_recruited: "Уже набрано",
    set_birth_day: "Установить день рождения",
    you_are_too_young: "Вы слишком молоды",
    requires_password: "Требуется пароль",
    wrong_password: "Неверный пароль",
    game_panel: "Панель игры",
    admin_panel: "Панель админа",
    game_notes: "Игровые записи",
    just_stop_picking_players: "Остановить набор игроков",
    appoint: "Назначить",
    choosing_word: "Выбор слова",
    for_whom_we_choose: "Кому выбирают",
    who_choose: "Кто выбирает",
    playing_now: "Идет игра",
    change: "Изменить",
    to_change_word_do_this: "Чтобы изменить загаданное слово наведите на него курсор и нажмите ИЗМЕНИТЬ",
    not_hear_anyone: "никого не слышит",
    change_own_word: "изменить свое слово",
    players_is_not_picking: "Набор игроков прекращен",
    club: "Клуб",
    community: "Сообщество",
    clan: "Клан",
    cartel: "Картель",
    team: "Команда",
    company: "Компания",
    max_number_participants: "Максимальное количество участников",
    talks: "Общение",
    iamalive: "Я живое?",
    made_request: "Заявка отправлена",
    decline_request: "Отменить запрос",
    you_are_member: "Вы состоите",
    you_are_admin: "Вы админ",
    joined_unjoin: "Вы участвуете (перестать)",
    edit: "Изменить",
    editing_community: "Редактирование сообщества",
    willbe_comein_event_button: "Здесь будет кнопка ВОЙТИ, когда событие начнется",
    comein: "Войти",
    dota2_heroes: "Герои Dota2",
    mixup_entered_players: "Замешать зашедших игроков",
    sign_up_vk: "Зарегистрироваться через VK",
    alreadytaken: "Уже занят",
    login_vk: "Войти через VK",
    set_nickname: "Установить никнейм",
    days: "д.",
    minutes: "мин.",
    hours: "ч.",
    standart_max_room_size: "Максимальный размер комнат для общения: 10 человек",
    standart_dont_see_over_rooms: "Вы не видите комнаты, которые завершили набор собеседников",
    standart_cant_create_communities: "Нельзя создавать сообщества",
    premium_create_big_rooms: "Cоздание комнат для общения большего размера",
    premium_join_all_open_rooms: "Вы видете все открытые комнаты и можете в них заходить в качестве зрителя",
    premium_can_create_communities: "Вы можете создать 3 сообщества",
    premium_buy_button_text: "490 руб в месяц",
    premium_call_to_action_text: "Внеси свой вклад в развитие сервиса",
    hackword_rules: "Игра очень похожа на Alias или Шляпу. Один человек из команды пытается объяснить слово, не используя однокоренные, чтобы его сокомандники угадали. Загадывающий отмечает угаданные слова и также может слова пропускать. Если кто-то несогласен с тем, что слово защитали, или наоборот - это после раунда можно поменять.",
    iamalive_rules: "Игра похожа на игру 'Кто я?', каждому игроку придумывают слово или словосочетание, которое он должен угадать. Все эти слова видят кроме того, кому они загаданы. Игроки по очереди задают вопросы на которые можно ответить да или нет и пытаются угадать свои слова. Можно загадывать по одному вопросу, можно пока не будет ответ 'нет', максимум 3 вопроса.",
    communities_after_create: "После создания сообщества вы сможете добавить картинку эмблемы: на странице сообщества нажмите ИЗМЕНИТЬ"
  },
};
import React from 'react';
import { Typography } from '@mui/material';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const FlatChatMsg = ({
	name,
	msg
}) => {	
	return (
		<Typography variant='body2'>
			<b style={{color: stringToColor(name),}}>{name}:&nbsp;</b> 
			{msg}
		</Typography>
	);
}; 

export default FlatChatMsg;
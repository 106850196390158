import FlatChat from "../common/FlatChat/FlatChat"
import {useParams} from "react-router-dom";
import { Container, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';


const BannedChat = () => {
	let { user_id } = useParams();

    return (		<Box
        sx={{
            minHeight: '100vh',
            bgcolor: 'background.default',
            color: 'text.primary',
        }}
    >
        <Container maxWidth="lg">

            <FlatChat occasionID={parseInt(user_id)} occasionType="banned"></FlatChat>
        </Container>
    </Box>)
}

export default BannedChat;

import Api from "./Api"



const getLocalToken = () => {
    let token = localStorage.getItem('AbracadabraBUMM');
    if (token === null) {
      return null
    }
    return token;
  };

  const checkAuth = async () => {
    return await Api.post(`/auth/login`, {})
  }


  const TokenService = {
    getLocalToken,
    checkAuth,
  };
  
  export default TokenService;
import {useRecoilState,} from 'recoil';
import {ChatWSOpened, ChatWS, ChatWSRestartTimeout} from "./atoms/Connection"
import React, {useState, useEffect} from 'react';
import {domainNameAPI, apiPort} from "../../environment"
import TokenService from "../../services/TokenService"
import {ChatMessages} from "./atoms/Chat"

let ws = null

let apiUrl = "ws://"+domainNameAPI+apiPort+"/chats/ws"

export const sendMsgChat = (ws, type, obj)=>{
    if (ws == null) {
        console.log("webRTCClient is null");
        return
    }
    try {
        ws.send(JSON.stringify({'Type': type, 'Payload': obj}));
    } catch (error) {
        console.error(error);
    }
}

function connect(atomValues, atomSets, props, reconnect) {
    let {wsOpened, chatWSRestartTimeout, chatMessages,} = atomValues
    let {setWSOpened, setChatWS, setChatWSRestartTimeout, setChatMessages} = atomSets

    if (reconnect) {
        ws = new WebSocket(apiUrl)
        setChatWS({ws: ws})
    }
    if (ws == null) {
        return
    }


    ws.onopen = () => {
        let newState = Object.assign({}, wsOpened);
        newState.opened = true;
        setWSOpened(newState);
        console.log('Chat WebSocket Client Connected');

        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalToken(), 'OccasionID': props.occasionID, 'OccasionType': props.occasionType, }}));
    };

    ws.onclose = () => {
        if (chatWSRestartTimeout.timeout != 0) {
            clearInterval(chatWSRestartTimeout.timeout)
        }
        let newState = Object.assign({}, wsOpened);
        newState.opened = false;
        setWSOpened(newState);
        let rTimeOut = setTimeout(function() {
            connect(atomValues, atomSets, props, true);
        }, 2000);
        setChatWSRestartTimeout({timeout: rTimeOut})
    };

    ws.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        switch (msg.Type) {
            case "AllMessagesToShow":
                processAllMessagesToShow(msg, setChatMessages)
                break;
            case "NewMessage":
                processNewMessage(msg, chatMessages, setChatMessages)
                break;                
            case "ErrorCode":
                if (msg.Payload.Code == 800) {
                    console.log("Problems with auth")
                }
                break                
        }
    }
}

function ChatController(props) {
    const [wsOpened, setWSOpened] = useRecoilState(ChatWSOpened); 
    const [chatWSRestartTimeout, setChatWSRestartTimeout]  = useRecoilState(ChatWSRestartTimeout);
    const [chatWS, setChatWS]  = useRecoilState(ChatWS);
    const [chatMessages, setChatMessages]  = useRecoilState(ChatMessages);

    useEffect(()=> {
        ws = new WebSocket(apiUrl)
        setChatWS({ws: ws}) // , TokenService.getLocalAccessToken()
    }, []);

    useEffect(()=> {
        connect({wsOpened, chatWSRestartTimeout, chatMessages,}, {setWSOpened, setChatWS, setChatWSRestartTimeout, setChatMessages}, props, false)
    }, []);
    return (
        <span>
        </span>
    )
}

export default ChatController;

const processAllMessagesToShow = (msg, setChatMessages)=>{
    msg.Payload.Messages.sort((a, b)=>{if (a.Datetime > b.Datetime) {return -1} else if (a.Datetime < b.Datetime) {return 1} else {return 0}})
    setChatMessages({
        messages: msg.Payload.Messages,
    })
}

const processNewMessage = (msg, chatMessages, setChatMessages)=>{
    setChatMessages((currentState) => {
        return {messages: [msg.Payload.Message, ...currentState.messages,],}
    })
}
import Api from "./Api"



const getLastPage = async function(pageSize) {
    return await Api.post(`/users/lastpage`, {PageSize: pageSize})
}

const getPage = async function(pageSize, pageNum) {
    return await Api.post(`/users/page`, {PageSize: pageSize, PageNum: pageNum})
}

const findUserByNick = async function(searchString) {
    return await Api.post(`/users/findUserByNick`, {SearchString: searchString})
}

const getUserInfo = async function(userID) {
    return await Api.post(`/users/getUserInfo`, {UserID: parseInt(userID)})
}

const getUserMessages = async function(userID) {
    return await Api.post(`/users/getUserMessages`, {UserID: parseInt(userID)})
}

const banUser = async function(userID) {
    return await Api.post(`/users/banUser`, {UserID: parseInt(userID)})
}
const unbanUser = async function(userID) {
    return await Api.post(`/users/unbanUser`, {UserID: parseInt(userID)})
}
const removeAllMessages = async function(userID) {
    return await Api.post(`/users/removeAllMessages`, {UserID: parseInt(userID)})
}

const getLastUserReports = async function() {
    return await Api.post(`/users/getLastReports`, {})
}

const getUserReports = async function(userID) {
    return await Api.post(`/users/getUserReports`, {UserID: parseInt(userID)})
}

const UserService = {
    getLastPage,
    getPage,
    findUserByNick,
    getUserInfo,
    getUserMessages,
    banUser,
    unbanUser,
    removeAllMessages,
    getLastUserReports,
    getUserReports,
};
  
export default UserService;
import HeaderMenu from "../main/HeaderMenu"
import OrderService from "../services/OrderService"
import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import {SuccessMessage,} from "../constants";
import ManageAccounts from "@mui/icons-material/ManageAccounts"
import { IconButton, Chip } from '@mui/material';
import {Order} from "./OrdersUser"



const LastOrders = ()=>{
    const [lastOrders, setLastOrders] = useState([]);


    useEffect(()=> {
        OrderService.getOrdersLast().then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setLastOrders(response.data.Orders.Orders)
            }
        })
    },[])
    return (
        <div>
            <HeaderMenu/>
            <br/>
            {lastOrders.map((item, index) => (
							<div>
									<Order
                                        key={index}
										OrderID={item.ID}
                                        Prolongate={item.Prolongate}
                                        HavePurchase={item.HavePurchase}
										Nickname={item.Nickname}
									/>
							</div>
						))}
        </div>
    )
}

export default LastOrders;
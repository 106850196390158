import Api from "./Api"







const getUserOrders = async function(userID) {
    return await Api.post(`/orders/user`, {UserID: parseInt(userID)})
}

const getOrdersLast = async function() {
    return await Api.post(`/orders/last`, {})
}

const getOrderDetails = async function(orderID) {
    return await Api.post(`/orders/details`, {OrderID: parseInt(orderID)})
}


const OrderService = {
    getUserOrders,
    getOrdersLast,
    getOrderDetails,
}

export default OrderService;
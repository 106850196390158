import HeaderMenu from "../main/HeaderMenu"
import TalkService from "../services/TalkService"
import {SuccessMessage, TalksPageSize} from "../constants";
import React, { useEffect, useState } from "react";
import { Pagination, Stack, Tab, Tabs, Box, IconButton, Chip } from '@mui/material';




const Talks = () => {
    const [talksToShow, setTalksToShow] = useState([]);
    const [countOfTalks, setCountOfTalks] = useState(0);
    const [pagesCount, setPagesCount] = useState(0);
    const [page, setPage] = useState(0);

    useEffect(()=> {
        TalkService.getLastPage(TalksPageSize).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setTalksToShow(response.data.Data.Talks)
                setCountOfTalks(response.data.Data.CountOfTalks)
                setPagesCount(response.data.Data.PagesCount)
                setPage(response.data.Data.PagesCount)
            }
        })
    }, []);
    useEffect(()=> {
        TalkService.getPage(TalksPageSize, page,).then((response)=>{
            if (response.data.Success == SuccessMessage) {
               // console.log("rooms", response.data.Data.Rooms)
               setTalksToShow(response.data.Data.Talks)
            }
        })
    }, [page]);

    if (!talksToShow) {
        return (<div><HeaderMenu/>Have No talks???</div>)
    }

    let talksList = []
    let i = 0
    for (const v of talksToShow) {
        talksList.push(<Talk key={i} talk={v}/>)
        i++
    }
    const listStyle = {
        border: "1px solid black",
        marginTop: "10px"
    }
    const pagesChange = (e, v)=>{
		setPage(v)
	}    
    return (
        <div>
            <HeaderMenu/>
            <br/>
            <br/>
            <div>Количество: {countOfTalks}</div>
            <div>Последняя страница:</div>
            <div style={listStyle}>
                {talksList}
            </div>
            <Box style={{ display: "flex", justifyContent: 'center'}}>
                {pagesCount > 1 ? <Pagination count={pagesCount} shape="rounded" page={page} onChange={pagesChange}/> : null}
            </Box>            
        </div>
    )
}

export default Talks

function Talk(props) {
    const nicknameStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "7px",
    }
    const statusesStyle = {
        padding: "7px",
    }
    const entryStyle = {
        border: "1px solid black",
    }
    return (
        <div style={entryStyle}>
            <span style={nicknameStyle}>{props.talk.Name}</span>
            {props.talk.EighteenPlus ? <span style={statusesStyle}>18+</span> : <span></span>}
            <span style={statusesStyle}>{props.talk.Tags}</span>
            <span style={statusesStyle}>{props.talk.CreatorID}</span>
        </div>
    )
}

// type TalkInfo struct {
// 	ID           uint64   `json:"ID"`
// 	Name         string   `json:"Name" db:"name"`
// 	EighteenPlus bool     `json:"EighteenPlus" db:"eighteen_plus"`
// 	Tags         []string `json:"Tags" db:"tags"`
// 	CreatorID    uint64   `json:"CreatorID" db:"creator_id"`
// }
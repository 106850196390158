



// Common


// Dev
// export const domainNameAPI = "dev.mnogokomnat.ru"
// export const apiPort = ":8091"
// export const protocolAPI = "https://"
// export const livekitServers = ["1",]


// Prod 
export const domainNameAPI = "admin.mnogokomnat.ru"
export const apiPort = ":8082"
export const protocolAPI = "https://"
export const livekitServers = ["1",]



import React, {useState, useEffect} from 'react';
import { Divider, IconButton, Stack, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './flat-chat.scss';
import FlatChatMsg from './FlatChatMsg/FlatChatMsg';
import ChatController from './ChatController';
import {sendMsgChat} from "./ChatController"
import {useRecoilState,} from 'recoil';
import {ChatWS} from "./atoms/Connection"
import {ChatMessages} from "./atoms/Chat"
import {MaxChatMessageLength} from "../../constants"
import {FormattedMessage} from "react-intl";

const FlatChat = (props) => {
	const [chatWS, setChatWS]  = useRecoilState(ChatWS);
	const [chatMessages, setChatMessages]  = useRecoilState(ChatMessages);

	const [messageError, setMessageError] = useState(null)
	const [message, setMessage] = useState("")

	const sendMessage = ()=>{
		if (message.length <= MaxChatMessageLength) {
			sendMsgChat(chatWS.ws, "SendMessage", {Message: message})
			setMessage("")
			setMessageError(null)
		} else {
			setMessageError(<span><FormattedMessage id="cantbelonger"/> {MaxChatMessageLength}</span>)
		}
	}
	useEffect(()=> {
        setChatMessages({messages: []})
    }, []);
	return (
		<Stack
			className='flat-chat'
			direction="column" useFlexGap={true}
		>
			<ChatController occasionID={props.occasionID} occasionType={props.occasionType}></ChatController>
			<Stack padding={3} spacing={1} className="flat-chat__body" direction="column" alignItems="flex-start">
				{chatMessages.messages.map((item, index) => (
					<FlatChatMsg
						name={item.Nickname}
						msg={item.Message}
						key={index}
					/>
				))}
			</Stack>			
			
			<Divider />

			<Stack direction="row" spacing={2} paddingX={3} paddingY={1} alignItems="flex-start">				
				<TextField
					id="outlined-multiline-flexible"
					multiline
					maxRows={4}
					fullWidth
					value={message}
					onChange={(e)=>{
						setMessage(e.target.value)
						if (e.target.value.length > MaxChatMessageLength) {
							setMessageError(<span><FormattedMessage id="cantbelonger"/> {MaxChatMessageLength}</span>)
							return
						}
						setMessageError(null)
					}}
					helperText={messageError}
					error= {messageError != null}
        		/>

				<IconButton aria-label="delete" onClick={sendMessage}>
					<SendIcon />
				</IconButton>
			</Stack>
		</Stack>
	);
};

export default FlatChat;